<!-- @format -->
<template>
    <div class="field" :class="[isHorizontal ? 'is-horizontal' : '']">
        <template v-if="isHorizontal">
            <div v-if="label" class="field-label is-normal">
                <label v-if="label" class="label" :class="labelClass">{{ label }}</label>
            </div>
        </template>

        <template v-else>
            <label v-if="label" class="label">{{ label }}</label>
        </template>
           
        <div class="field-body">
            <div class="field" :class="fieldClasses">
                <div class="control">
                    <template v-if="onlyDate">
                        <flat-pickr v-model="displayOutput" :disabled="disabled" :name="name" :config=configDate @input="input" :placeholder="placeholder">
                        </flat-pickr>
                    </template>

                    <template v-if="!onlyDate && !onlyTime">
                        <flat-pickr v-model="displayOutput" :disabled="disabled" :name="name" :config=config @input="input" :placeholder="placeholder">
                        </flat-pickr>
                    </template>

                    <template v-if="onlyTime">
                        <flat-pickr v-model="displayOutputTime" :disabled="disabled" :name="name" :config=configTime @input="input" :placeholder="placeholder">
                        </flat-pickr>
                    </template>

                    <template v-if="showIcon">
                        &nbsp;<span class="icon" data-toggle><fa-icon icon="calendar" /></span>
                    </template>
                       
                    <p v-if="note">{{ note }}</p>
                </div>
                <input-error :field="name" :errors="errors" />
            </div>
        </div>
    </div>
</template>

<script>
import { toDate } from '@/helpers/date.js';
import InputError from './InputError.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

// Add a now button to flatpicker
function NowButtonPlugin(config) {
  return function(fp) {
    const createNowButton = () => {
      const nowButton = document.createElement("button");
      nowButton.textContent = "Now";
      nowButton.type = "button";
      nowButton.className = "button is-success flatpickr-now-button";
      nowButton.addEventListener("click", () => {
        fp.setDate(new Date(), true);
        fp.close();  // Close the calendar
      });
      return nowButton;
    };

    // Use config.onReady to hook into the Flatpickr lifecycle
    if (!fp.config.onReady) {
      fp.config.onReady = [];
    }
    fp.config.onReady.push(function() {
      const nowButton = createNowButton();
      fp.calendarContainer.appendChild(nowButton);
    });
  };
}

export default {
    components: {
        InputError,
        flatPickr
    },
    props: {
        value: {
            required: true,
            validator: (prop) => typeof prop === 'string' || prop === null
        },
        isHorizontal: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        inputClass: {
            type: String,
            default: ''
        },
        labelClass: {
            type: String,
            default: ''
        },
        errors: {
            type: Array,
            default: () => []
        },
        format: {
            type: String,
            default: 'MM/DD/YYYY HH:mm'
        },
        formatted: {
            type: String,
            default: 'MM/DD/YYYY HH:mm'
        },
        outputFormat: {
            type: String,
            default: 'YYYY-MM-DD HH:mm:ss'
        },
        onlyDate: {
            type: Boolean,
            default: false
        },
        onlyTime: {
            type: Boolean,
            default: false
        },
        minDate: {
            type: String,
            default: ''
        },
        maxDate: {
            type: String,
            default: ''
        },
        note: {
            type: String,
            default: ''
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        showFormat: {
            type: String,
            default: 'm/d/Y H:i'
        },
        backdateFormat: {
            type: String,
            default: 'Y-m-d H:i:s'
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            displayOutput: null,
            displayOutputTime: null,
            config: {
              wrap: true,
              altFormat: this.showFormat,
              altInput: true,
              dateFormat: this.backdateFormat,
              enableTime: true,
              altInputClass: this.inputClass,
              defaultDate: this.displayOutput,
              minuteIncrement: 1,
              inline: this.inline,
              time_24hr: true,
              plugins: [NowButtonPlugin()]
            }, 
            configDate : {
              wrap: true,
              altFormat: 'm/d/Y',
              altInput: true,
              dateFormat: 'Y-m-d',
              enableTime: false,
              altInputClass: this.inputClass,
              defaultDate: this.displayOutput,
              minDate: this.minDate,
              maxDate: this.maxDate,
              inline: this.inline,
              plugins: [NowButtonPlugin()]
            },
            configTime : {
              wrap: true, // set wrap to true only when using 'input-group'
              dateFormat: 'h:i K',
              enableTime: true,
              altInput: true,
              altInputClass: this.inputClass,
              defaultDate: this.value,
              noCalendar: true,
              time_24hr: true,
              inline: this.inline,
              plugins: [NowButtonPlugin()]
            },  
        };
    },

    computed: {
        fieldClasses() {
            return [this.classes, this.hasInputError(this.errors, this.name)];
        }
    },

   
    mounted() {
        if (this.value && !this.onlyTime) {
            this.displayOutput = toDate(this.value).format(this.outputFormat);
        }

        if (this.value && this.onlyTime) {
            this.displayOutputTime = this.value;
        }
    },

    methods: {
        
        input($event) {
            // No need to parse as well
            if (this.onlyTime) {
                this.$emit('update:value', this.displayOutputTime);
                return;
            }
            const currentDate = toDate(this.value);
            const nextDate = toDate($event);

            if (!currentDate.isSame(nextDate, 'day')) {
                this.showPicker = false;
            }

            this.$emit('update:value', this.displayOutput);
        },

        
        
    }
};
</script>

<style>
/* Style for the "Now" button */
.flatpickr-now-button {
    padding: 5px 10px;
    margin-top: 5px;
    margin-bottom: 10px;
}
</style>